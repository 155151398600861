<template>
    <div class="main-content">
        <div class="breadcrumb">
            <Breadcrumb>
                <BreadcrumbItem>统计报表</BreadcrumbItem>
                <BreadcrumbItem>目标达成</BreadcrumbItem>
            </Breadcrumb>
        </div>
        <div class="filter">
            <Form label-colon inline>
                <FormItem>
                    <Select v-model="filter.type" placeholder="请选择目标周期" label-in-value @on-change="handleChangeType" filterable clearable>
                        <Option v-for="item in objectiveTypeArray" :value="item.id" :key="item.id">{{item.name}}</Option>
                    </Select>
                </FormItem>
                <FormItem v-if="filter.type === 455">
                    <DatePicker placeholder="请选择开始日期" v-model="filter.startDate" type="date" format="yyyy-MM-dd" :options="weekOptions" @on-change="handleDateChange"></DatePicker>
                </FormItem>
                <FormItem v-if="filter.type === 460">
                    <DatePicker v-model="filter.startDate" type="month" format="yyyy-MM" placeholder="请选择月份" @on-change="handleDateChange"></DatePicker>
                </FormItem>
                <FormItem v-if="filter.type === 470">
                    <DatePicker v-model="filter.startDate" type="year" format="yyyy" placeholder="请选择年份" @on-change="handleDateChange"></DatePicker>
                </FormItem>
                <FormItem v-if="filter.type === 465">
                    <quarter-picker v-model="filter.quarterStr" @on-change="handleChangeQuarter"></quarter-picker>
                </FormItem>
                <FormItem>
                    <Select v-model="filter.userId" placeholder="请选择用户" filterable clearable>
                        <Option v-for="item in userArray" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <deptTreeSelect v-model="filter.departmentId" :options="departmentOpts" placeholder="请选择所属组织" noOptionsText="暂无数据" noResultsText="没有匹配数据" clearable />
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="doSearching">搜索</Button>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="resetting">重置</Button>
                </FormItem>
                <FormItem>
                    <Button type="primary" icon="md-cloud-download" @click="handleExport" v-if="permissionButton.export">导出</Button>
                </FormItem>
            </Form>
        </div>
        <div class="table">
            <div class="table-list">
                <Table border stripe :columns="columns" :data="dataList" :loading="loading"></Table>
            </div>
            <div class="table-footer">
                <Row>
                    <Col class="total" span="6">共 {{ total }} 条记录 第 {{ pageNum }}/{{ pages }} 页</Col>
                    <Col class="pagenation" span="18">
                        <Page :page-size="pageSize" :total="total" :current="pageNum"
                              :page-size-opts="[15, 20, 50, 100]"
                              @on-change="changePage" @on-page-size-change="changPageSize" show-sizer show-elevator/>
                    </Col>
                </Row>
            </div>
        </div>
    </div>
</template>

<script>
import permission from '@/common/permission'
import {reqGetDictionaryListByCode, reqGetUserList} from "../../../api/system-api";
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import deptTreeSelect from "@riophae/vue-treeselect";
import {reqGetDepartmentTreeOpts, reqGetGoalReport, reqExportGoalReport} from "../../../api/admin-api";
import quarterPicker from '../../../components/quarter/QuarterPicker';

export default {
    name: 'goalReport',
    components: {deptTreeSelect, quarterPicker},
    data() {
        return {
            filter: {
                type: null,
                typeName: null,
                startDate: null,
                endDate: null,
                userId: null,
                departmentId: null,
                quarterStr: null,
            },
            userArray: [],
            departmentOpts: [],
            objectiveTypeArray: [],
            weekOptions: {
                disabledDate(date) {
                    return (date.getDay() !== 1);
                }
            },
            loading: false,
            dataList: [],
            permissionButton: {
                export: false,
            },
            total: 0,
            pages: 1,
            pageSize: 20,
            pageNum: 1,
            columns: [
                {
                    title: '用户',
                    key: 'stakeholderName',
                    width: 200,
                },
                {
                    title: '所属组织',
                    key: 'departmentName',
                    tooltip: 'true',
                    minWidth: 150,
                },
                {
                    title: '目标金额',
                    align: 'center',
                    width: 150,
                    render: (h, params) => {
                        return h('span',  (params.row.amount ? params.row.amount.toFixed(2) : ''))
                    }
                },
                {
                    title: '回款金额',
                    align: 'center',
                    width: 150,
                    render: (h, params) => {
                        return h('span', (params.row.receive ? params.row.receive.toFixed(2) : ''))
                    }
                },
                {
                    title: '回款数',
                    key: 'countsOfReceive',
                    align: 'center',
                    width: 100,
                },
                {
                    title: '完成比例',
                    key: 'achievingRate',
                    align: 'center',
                    width: 100,
                    render: (h, params) => {
                        return h('span' , (params.row.achievingRate ? params.row.achievingRate + '%' : ''))
                    }
                }
            ],
        }
    },
    methods: {
        init() {
            reqGetDictionaryListByCode({code: "objective_type"}).then((res) => {
                this.objectiveTypeArray = res.data.data;
            }).catch(() => {
                this.objectiveTypeArray = [];
            });

            reqGetUserList().then((res) => {
                this.userArray = res.data;
            }).catch(() => {
                this.userArray = [];
            });

            reqGetDepartmentTreeOpts().then(res => {
                this.departmentOpts = res.data.data;
            }).catch(()=>{
                this.departmentOpts = [];
            });
        },
        //分页点击，切换页面
        changePage(val) {
            this.pageNum = val;
            this.getGoalReport();
        },
        //每页显示的数据条数
        changPageSize(numOfRows) {
            //实时获取当前需要显示的条数
            this.pageSize = numOfRows;
            this.getGoalReport();
        },
        doSearching() {
            this.pageNum = 1;
            this.getGoalReport();
        },
        handleChangeType(item) {
            if (item) {
                this.filter.typeName = item.label;
            } else {
                this.filter.typeName = null;
            }
            this.filter.startDate = null;
            this.filter.endDate = null;
            this.filter.userId = null;
            this.filter.departmentId = null;
            this.filter.quarterStr = null;
        },
        handleDateChange(val, type) {
            if (type === 'date') {
                this.filter.startDate = this.$moment(val).format('YYYY-MM-DD');
                this.filter.endDate = this.$moment(val).add(6, 'days').format('YYYY-MM-DD');
            }
            if (type === 'month') {
                this.filter.startDate = val + '-01';
                this.filter.endDate = this.$moment(this.filter.startDate).add(1, 'M').subtract(1, 'days').format('YYYY-MM-DD');
            }
            if (type === 'year') {
                this.filter.startDate = val + '-01-01';
                this.filter.endDate = val + '-12-31';
            }
        },
        handleChangeQuarter(message, year, value) {
            this.filter.quarterStr = message;
            if (value === 1) {
                this.filter.startDate = year + '-01-01';
                this.filter.endDate = year + '-03-31';
            }
            if (value === 2) {
                this.filter.startDate = year + '-04-01';
                this.filter.endDate = year + '-06-30';
            }
            if (value === 3) {
                this.filter.startDate = year + '-07-01';
                this.filter.endDate = year + '-09-30';
            }
            if (value === 4) {
                this.filter.startDate = year + '-10-01';
                this.filter.endDate = year + '-12-31';
            }
        },
        getGoalReport() {
            this.loading = true;
            this.dataList = [];
            let params = Object.assign({
                pageNum: this.pageNum,
                pageSize: this.pageSize,
            }, this.filter);

            reqGetGoalReport(params).then((res) => {
                this.loading = false;
                this.pageNum = res.data.data.current
                this.pages = res.data.data.pages
                this.total = res.data.data.total
                this.dataList = res.data.data.records;
            }).catch(() => {
                this.loading = false;
                this.dataList = [];
            });
        },
        resetting() {
            this.loading = true;
            this.dataList = [];
            this.filter.type= null;
            this.filter.typeName=null;
            this.filter.startDate= null;
            this.filter.endDate= null;
            this.filter.userId= null;
            this.filter.departmentId= null;
            this.filter.quarterStr= null;
            let params = Object.assign({
                pageNum: 1,
                pageSize: 20,
            }, this.filter);
            reqGetGoalReport(params).then((res) => {
                this.loading = false;
                this.pageNum = res.data.data.current
                this.pages = res.data.data.pages
                this.total = res.data.data.total
                this.dataList = res.data.data.records;
            }).catch(() => {
                this.loading = false;
                this.dataList = [];
            });
        },
        handleExport() {
            this.$Modal.confirm({
                title: '导出简历数据',
                content: '确定导出简历数据查询结果？',
                width: '320',
                onOk: () => {
                    reqExportGoalReport(this.filter).then((res)=>{
                        let filename = 'Goal.xlsx';
                        const disposition = res.headers['content-disposition'];
                        if (typeof disposition !== 'undefined') {
                            let deDisposition = decodeURIComponent(disposition);
                            filename = deDisposition.substring(deDisposition.indexOf('filename=') + 9, deDisposition.length);
                        }

                        let blob = new Blob([res.data], {type: 'application/vnd.ms-excel;charset=utf-8'}) //type这里表示xlsx类型
                        let downloadElement = document.createElement('a')
                        let href = window.URL.createObjectURL(blob) //创建下载的链接
                        downloadElement.href = href
                        downloadElement.download = filename //下载后文件名
                        document.body.appendChild(downloadElement)
                        downloadElement.click() //点击下载
                        document.body.removeChild(downloadElement) //下载完成移除元素
                        window.URL.revokeObjectURL(href) //释放掉blob对象
                    }).catch((err)=>{
                        this.$Message.error('导出失败')
                    })
                },
            })
        }
    },
    mounted() {
        let permissionId = this.$route.query.permissionId;
        permission.getPermissionButton(permissionId, this.permissionButton);
        this.init();
        this.getGoalReport();
    }
}
</script>
