<template>
    <div v-clickoutside="closePicker">
        <Input v-model="message" placeholder="请选择季度" :icon="arrowType" readonly
               @on-focus="show"
               @on-click="clickIcon"
               @mouseenter.native="handleInputMouseenter"
               @mouseleave.native="handleInputMouseleave"></Input>
        <div v-show="visible" class="pickerCard">
            <div class="pickHeader">
                <span class="pickerIconBtn leftIcon ivu-date-picker-prev-btn-arrow-double" @click="changeYear('last')">
                    <Icon type="ios-arrow-back"></Icon>
                </span>
                <span>
                    {{year}}年
                </span>
                <span class="pickerIconBtn rightIcon ivu-date-picker-next-btn-arrow-double" @click="changeYear('next')">
				    <Icon type="ios-arrow-forward"></Icon>
			    </span>
            </div>
            <div class="pickQuarterCard">
                <span v-for="item in dataList" :key="item.value" :class="item.disable ?'disableSpan': 'defaultSpan'" @click="handleClick(item)">
                    <em :class="!item.disable && quarter===item.value ? 'selected': 'defaultEm'">{{item.label}}</em>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
const clickoutside = {
    // 初始化指令
    bind (el, binding, vnode) {
        function documentHandler (e) {
            // 这里判断点击的元素是否是本身，是本身，则返回
            if (el.contains(e.target)) {
                return false;
            }
            // 判断指令中是否绑定了函数
            if (binding.expression) {
                // 如果绑定了函数 则调用那个函数，此处binding.value就是handleClose方法
                binding.value(e);
            }
        }
        // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
        el.__vueClickOutside__ = documentHandler;
        document.addEventListener('click', documentHandler);
    },
    unbind (el, binding) {
        // 解除事件监听
        document.removeEventListener('click', el.__vueClickOutside__);
        delete el.__vueClickOutside__;
    }
};
export default {
    name: 'quarter-picker',
    props: {
        options: { // 不可选日期
            type: Object,
            default: () => ({})
        },
        clearable: {
            type: Boolean,
            default: true
        },
        value: {
            type: [String],
            default: ''
        }
    },
    data () {
        return {
            visible: false,
            message: null,
            year: null,
            quarter: null,
            currentYear: null,
            currentQuarter: null,
            dataList: [
                {label: '第一季度', value: 1, disable: false},
                {label: '第二季度', value: 2, disable: false},
                {label: '第三季度', value: 3, disable: false},
                {label: '第四季度', value: 4, disable: false}
            ],
            showClose: false
        };
    },
    computed: {
        arrowType () {
            return this.showClose ? 'ios-close-circle' : 'ios-calendar-outline';
        }
    },
    directives: { clickoutside },
    watch: {
        value () {
            if (this.value !== this.message) {
                this.initData();
            }
        }
    },
    created () {
        this.currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth() + 1;
        if (currentMonth < 4) {
            this.currentQuarter = 1;
        } else if (currentMonth < 7) {
            this.currentQuarter = 2;
        } else if (currentMonth < 10) {
            this.currentQuarter = 3;
        } else {
            this.currentQuarter = 4;
        }
        this.initData();
    },
    methods: {
        initData () {
            if (this.value) {
                const arr = this.value.split('年');
                this.year = arr[0];
                if (arr[1] === '第一季度') {
                    this.quarter = 1;
                } else if (arr[1] === '第二季度') {
                    this.quarter = 2;
                } else if (arr[1] === '第三季度') {
                    this.quarter = 3;
                } else if (arr[1] === '第四季度') {
                    this.quarter = 4;
                } else {
                    this.quarter = this.currentQuarter;
                }
                this.message = this.value;
            } else {
                this.year = this.currentYear;
                this.quarter = this.currentQuarter;
                this.message = '';
            }
        },
        show () {
            this.handleData();
            this.visible = true;
        },
        handleInputMouseenter () {
            this.showClose = this.message && this.clearable;
        },
        handleInputMouseleave () {
            this.showClose = false;
        },
        clickIcon () {
            if (this.showClose) {
                this.quarter = null;
                this.message = null;
                this.$emit('on-change', this.message, this.year, null);
            }
        },
        changeYear (type) {
            if (type === 'last') {
                this.year--;
            } else {
                this.year++;
            }
            if (this.year === this.currentYear) {
                this.quarter = this.currentQuarter;
            } else {
                this.quarter = null;
            }
            this.handleData();
        },
        handleData () {
            if (this.options && this.options.disabledDate) {
                this.dataList.forEach(item => {
                    item.disable = this.options.disabledDate(this.year, item.value);
                });
            }
        },
        handleClick (item) {
            if (item.disable) return;
            this.quarter = item.value;
            this.message = this.year + '年' + item.label;
            this.$emit('on-change', this.message, this.year, item.value);
            this.closePicker();
        },
        closePicker () {
            this.visible = false;
        }
    },
};
</script>

<style scoped lang="less">
.pickerCard{
    width: 200px;
    font-size: 12px;
    z-index: 100;
    background: #fff;
    border-radius: 0.3rem;
    position:absolute;
    margin: 5px 0;
    box-shadow: 0 1px 6px rgba(0,0,0,.2);
    .pickHeader{
        height: 32px;
        line-height: 32px;
        text-align: center;
        border-bottom: 1px solid #e8eaec;
        .leftIcon{
            float: left;
        }
        .rightIcon{
            float: right;
        }
        .pickerIconBtn{
            display: inline-block;
            width: 20px;
            height: 24px;
            line-height: 26px;
            margin-top: 2px;
            text-align: center;
            cursor: pointer;
            color: #c5c8ce;
            transition: color .2s ease-in-out;
        }
    }
    .pickQuarterCard{
        margin: 10px 8px;
        white-space: normal;
        span {
            //width: 60px;
            height: 28px;
            line-height: 28px;
            margin: 10px 12px;
            border-radius: 3px;
            cursor: pointer;
            display: inline-block;
            em{
                padding: 0 10px;
                height: 28px;
                line-height: 28px;
                display: inline-block;
                margin: 0;
                font-style: normal;
                border-radius: 3px;
                text-align: center;
                transition: all .2s ease-in-out;
            }
        }
        .disableSpan{
            cursor: not-allowed;
            color: #c5c8ce;
            background: #f7f7f7;
        }
        .defaultSpan:hover .defaultEm {
            background: #FFF9E6;
        }
        .defaultSpan .selected{
            background: #FBAD39;
            color: #fff;
        }
    }
}
</style>
